
import Swiper from 'swiper';
import { Navigation, Pagination, Thumbs, FreeMode, EffectFade, Autoplay  } from 'swiper/modules';

var sliderThumbnail = new Swiper('.slider-thumbnail', {
  modules: [EffectFade, Autoplay],
  slidesPerView: 1,
  freeMode: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  speed: 1500,
 
  effect: 'fade',
    fadeEffect: {           // added
        crossFade: true     // added(resolve the overlapping of the slides)
    },     
});

var slider = new Swiper('.slider', {
  modules: [Navigation, Thumbs, EffectFade, Autoplay],
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  loop: true,
  freeMode: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  speed: 3000,
  autoplay: true,
  effect: 'fade',         // apply fade effect
  fadeEffect: {           
      crossFade: true     // resolve the overlapping of the slides
  },
     
  thumbs: {
    swiper: sliderThumbnail
  }
});

import gsap from '../../js/gsap/gsap.min.js';
import { ScrollTrigger } from '../../js/gsap/ScrollTrigger.min.js';
import { DrawSVGPlugin  } from '../../js/gsap/DrawSVGPlugin.min.js';
import { ScrollToPlugin  } from '../../js/gsap/ScrollToPlugin.min.js';
import { SplitText  } from '../../js/gsap/SplitText.min.js';
import { CSSRulePlugin  } from '../../js/gsap/CSSRulePlugin.min.js';
import { Flip  } from '../../js/gsap/Flip.min.js';

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, ScrollToPlugin, CSSRulePlugin, SplitText, Flip);

let mm = gsap.matchMedia();

if ( document.getElementById("mask")) {

mm.add("(min-width: 992px)", () => {
if (document.querySelector('.home .has-mask-fill')) {

  var tl = gsap.timeline();
  const animateMe = gsap.utils.toArray(".home-intro__highlight-word");
  animateMe.forEach((chunk) => {
    const split = new SplitText(chunk, {
      type: "chars",
    });

    tl.to(split.chars, {
      color: "rgba(121,137,61,.9)",
          ease: "power3.inOut",
          duration: 0.5,
          stagger: {
            each: 0.075,
            repeat: 1,
            yoyo: true,
          },
         
    },  "<50%" );
  });
  var hasMaskFill = document.querySelector('.home .has-mask-fill');
  ScrollTrigger.create({
  trigger: ".home .has-mask-fill",
  start: "top 85%",
  end: () => `+=${hasMaskFill.offsetHeight * 2}`,
  //markers: {startColor: "red", endColor: "blue"},
  animation: tl,
  toggleActions: 'play none restart reset',
});


}
});
}

gsap.utils.toArray(".button_slide--line").forEach(bline => {
  gsap.from(bline, {
    scaleX: 0,
    duration: 1,
    transformOrigin: "left center",
    ease: "power2.inOut",
    scrollTrigger: {
      trigger: '.button_slide--line',
      start: "top 80%",
      toggleActions: "play none none reverse",
      //markers: true,
    }
  });
});




gsap.utils.toArray(".line-left").forEach(line => {
  gsap.from(line, {
    scaleX: 0,
    duration: 1,
    transformOrigin: "left center",
    ease: "power2.inOut",
    scrollTrigger: {
      trigger: '.project__row',
      start: "top 80%",
      toggleActions: "play none none reverse",
      //markers: true,
    }
  });
});

gsap.utils.toArray(".line-right").forEach(line => {
  gsap.from(line, {
    scaleX: 0,
    duration: 1,
    transformOrigin: "right center",
    ease: "power2.inOut",
    scrollTrigger: {
      trigger: '.project__row',
      start: "top 80%",
      toggleActions: "play none none reverse",
      //markers: true,
    }
  });
});

var elementFirst = document.querySelector('.sticky-nav');
gsap.utils.toArray(".line-theme").forEach(line => {
  gsap.from(line, {
    scaleX: 0,
    duration: 1,
    transformOrigin: "right center",
    ease: "power2.inOut",
    scrollTrigger: {
      trigger: line,
      start: "top 80%",
      end: "top +=32",
      toggleActions: "play reverse play reverse",
      onLeave: () => myfunction(),
      onEnterBack: () => myfunctionBack(),
      //smarkers: true,
    }
  });
});


    
    

function myfunction() {
  gsap.fromTo('.line-sticky' , {  duration: 1,  scaleX: 1, transformOrigin: "right center", ease: "power2.inOut", }, {scaleX: 0,});
  gsap.fromTo('.sticky-logo', 1 , {delay: .2, opacity: 0}, {opacity:1});
 
  elementFirst.classList.add('active');
  console.log('leave');
};
function myfunctionBack() {
  elementFirst.classList.remove('active');
  gsap.to('.line-sticky' , { duration: 1,   width: '100%', transformOrigin: "right center", ease: "power2.inOut", });
  console.log('leave-back');
};

let revealContainers = document.querySelectorAll(".reveal");
revealContainers.forEach((container) => {
  // var delayValue = parseInt(container.getAttribute("data-delay")) || 0;
  // var delayAmount = delayValue * 0.1;
  let image = container.querySelector("img");
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      toggleActions: "restart none none reset"
    }
  });  
  tl.set(container, { autoAlpha: 1 });
  tl.from(image, 1.2, {
    xPercent: 0,
    scale: 1.1,
    ease: Power2.out,
  });

});

var rule = CSSRulePlugin.getRule(".line-col:after");
gsap.set(rule, {
  cssRule: {
    scaleY:0,
  }
});
gsap.utils.toArray(rule).forEach(rule => {
  gsap.to(rule, {
      duration: 1,
    cssRule: {
      scaleY:1,
    },
    transformOrigin: "top left",
    ease: "power2.inOut",
    scrollTrigger: {
      trigger: '.project__row',
      start: "top 80%",
      toggleActions: "play none none reverse"
    }
  });
});


let currentPage = 1;
jQuery('#load-more').on('click', function() {
  
  currentPage++; // Do currentPage + 1, because we want to load the next page

  jQuery.ajax({
    type: 'POST',
    url: '/wp-admin/admin-ajax.php',
    dataType: 'json',
    data: {
      action: 'weichie_load_more',
      paged: currentPage,
    },
    success: function (res) {
      if(currentPage >= res.max) {
        jQuery('#load-more').hide();
      }
      jQuery('.new-project').append(res.html);
      ScrollTrigger.refresh();
      let revealContainers = document.querySelectorAll(".new-project .reveal");
revealContainers.forEach((container) => {
  let image = container.querySelector("img");
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: container,
      toggleActions: "restart none none reset"
    }
  });  
  tl.set(container, { autoAlpha: 1 });
  tl.from(image, 1.5, {
    xPercent: 0,
    scale: 1.2,
    delay: -1.5,
    ease: Power2.out
  });
});
    }
  });
});

const projectSwiper = new Swiper('.project-swiper', {
  // Optional parameters
  modules: [Navigation],
  loop: true,

  slidesPerView: 1,
  // Navigation arrows
  navigation: {
    nextEl: '.project-swiper-button-next',
    prevEl: '.project-swiper-button-prev',
  },
});


let swiper;
window.globals = {};
const body = document.getElementsByTagName("body")[0];
const lightboxImages = document.querySelectorAll("[lightbox-toggle] img");
const initLightbox = lightboxImages.length > 0;
const destroySwiper = (swiper, timeout) => {
  setTimeout(() => {
    swiper.destroy(); 
  }, timeout);
};

const createLightboxSkeleton = () => {
  // Create skeleton for lightbox
  const lightbox = document.createElement("div");
  const lightboxContainer = document.createElement("div");
  const lightboxClose = document.createElement("div");
  const swiperContainer = document.createElement("div");
  const swiperWrapper = document.createElement("div");
  const swiperBtnNext = document.createElement("div");
  const swiperBtnPrev = document.createElement("div");
  const swiperPagination = document.createElement("div");
  const swiperBtnRow = document.createElement("div");

  // Add classes
  lightbox.classList.add("c-lightbox");
  lightboxContainer.classList.add("c-lightbox__container", "container", "position-relative");
  lightboxClose.classList.add("c-lightbox__close");
  lightboxClose.setAttribute("tabindex", "0");
  lightboxClose.innerHTML = "";
  swiperContainer.classList.add("swiper-container");
  swiperWrapper.classList.add("swiper-wrapper");
  swiperBtnNext.classList.add("c-lightbox-swiper-button-next");
  swiperBtnPrev.classList.add("c-lightbox-swiper-button-prev");
  swiperPagination.classList.add("swiper-pagination");
  swiperBtnRow.classList.add("swiper-btn-row");
  // Append created divs
  lightboxContainer.appendChild(lightboxClose);
  swiperContainer.appendChild(swiperWrapper);
  swiperContainer.appendChild(swiperBtnRow);
  swiperBtnRow.appendChild(swiperBtnNext);
  swiperBtnRow.appendChild(swiperBtnPrev);
  swiperContainer.appendChild(swiperPagination);
  lightboxContainer.appendChild(swiperContainer);
  lightbox.appendChild(lightboxContainer);
  body.appendChild(lightbox);

  // Set variables to reference the lightbox
  globals.lightboxRef = document.querySelector(".c-lightbox");
  globals.swiperWrapperRef = document.querySelector(
    ".c-lightbox .swiper-wrapper"
  );
};

if (initLightbox) {
  createLightboxSkeleton();

  // The rest of the code will go here
  lightboxImages.forEach(function (el, index) {
    // Add click function to lightbox images
    el.addEventListener("click", imageClick, false);

    function imageClick() {
      // Clear swiper before trying to add to it
      globals.swiperWrapperRef.innerHTML = "";

      // Loop through images with lightbox data attr
      // Create html for lightbox carousel
      lightboxImages.forEach(function (img) {
        console.log(index);
        // Create clone of current image in loop
        const image = img.cloneNode(true);
        // Create divs
        const slide = document.createElement("div");
        const imageContainer = document.createElement("div"); 
        // Add classes
        slide.classList.add("swiper-slide");
        imageContainer.classList.add("c-lightbox__image","second");
        // Append images to the slides, then slides to swiper wrapper
        imageContainer.appendChild(image);
        slide.appendChild(imageContainer);
        globals.swiperWrapperRef.appendChild(slide);
      });

      // Init Swiper
      swiper = new Swiper(".c-lightbox .swiper-container", {
        modules: [Navigation],
        initialSlide: index,
        loop: true,
        slidesPerView: 1,
        speed: 750,
        spaceBetween: 16,
        watchOverflow: true,
        navigation: {
          nextEl: ".c-lightbox-swiper-button-next",
          prevEl: ".c-lightbox-swiper-button-prev"
        },
        pagination: {
          el: ".swiper-pagination",
          type: "fraction"
        },
        zoom: true,
        fadeEffect: {
          crossFade: false
        },
        keyboard: {
          enabled: true,
          onlyInViewport: true
        },
        mousewheel: {
          sensitivity: 1,
          forceToAxis: true,
          invert: true
        }
      });

      // Add the class to open the lightbox
      // Add overflow hidden to the body to prevent scrolling
      globals.lightboxRef.classList.add("open");
      body.classList.add("overflowHidden");
    }
  });

  // Close lightbox on click
  document.addEventListener(
    "click",
    ({ target }) => {
      if (target.matches(".c-lightbox__close")) {
        destroySwiper(swiper, 250);
        globals.lightboxRef.classList.remove("open");
        body.classList.remove("overflowHidden");
      }
    },
    false
  );

  // Close lightbox on escape key press
  document.addEventListener("keydown", ({ key }) => {
    if (key === "Escape") {
      destroySwiper(swiper, 250);
      globals.lightboxRef.classList.remove("open");
      body.classList.remove("overflowHidden");
    }
  });
}



jQuery(".contact-register").on("click", function (e) {
  e.preventDefault();
  const href = jQuery(this).attr("href");
  jQuery("html, body").animate({ scrollTop: jQuery(href).offset().top }, 800);
});



